'use client';
import { useQueryString } from '@hooks/client';
import { useKeyboard } from '@react-aria/interactions';
import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { nanoid } from 'nanoid';
import { usePathname, useRouter } from 'next/navigation';
import React, { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounceCallback, useOnClickOutside } from 'usehooks-ts';

import { DropListSearch } from '@/shared/components/drop-list-search';
import { SEARCH_BAR_FILTERS } from '@/shared/components/drop-list-search/drop-list-search';
import { Popover } from '@/shared/components/input-search-autocomplete/popover';
import { Filter } from '@/shared/types/common';
import { Divider, Illustration, Typography } from '@/shared/ui';
import { IllustrationProps } from '@/shared/ui/illustration/illustration';
import { useStore } from '@/store/context';
import { SearchBarHistoryItem } from '@/store/header/header';

//export let Item: <T>(props: ItemProps<T>) => JSX.Element;
import styles from './input-search-autocomplete.module.scss';
export const InputSearchAutocomplete = observer(
  (props: { children?: ReactNode }) => {
    const { createQueryString, params } = useQueryString();
    const sortItems = SEARCH_BAR_FILTERS;
    const router = useRouter();
    const pathname = usePathname();

    let inputRef = useRef<HTMLInputElement>(null);
    let popoverRef = useRef<HTMLDivElement>(null);

    // Get props for the clear button from useSearchField
    let searchProps = {
      label: 'search',
    };
    const searchHistory = useStore()?.header;
    let searchState = useSearchFieldState(searchProps);

    let { inputProps } = useSearchField(
      searchProps,
      { ...searchState },
      inputRef,
    );

    let outerRef = useRef<HTMLDivElement>(null);

    const wrapperClasses = cn(styles.main_wrapper);
    const inputClasses = cn(styles.input_field);
    const inputWrapperClasses = cn(styles.input_wrapper);

    const [chipDropDownOpened, setChipDropDownOpened] = useState(false);
    const [isInputFocused, setIsFocused] = useState(false);

    const chipInitialValue = params['search-category']
      ? (sortItems.find(item => item.filter === params['search-category'][0]) ??
        null)
      : null;
    const toggleChipDropDown = () => {
      setChipDropDownOpened(!chipDropDownOpened);
    };

    const selectChip = (item: Filter) => {
      router.push(
        createQueryString({
          pathname: pathname,
          name: 'search-category',
          value: item.filter,
          asMultipleParams: false,
          resetBefore: true,
          excludeFromReset: ['search', 'sort'],
        }),
      );
      setChipDropDownOpened(false);
    };

    const resetChip = () => {
      router.push(
        createQueryString({
          pathname: pathname,
          name: 'search-category',
          value: chipInitialValue?.filter ?? '',
          asMultipleParams: false,
          enableToggle: true,
        }),
      );
    };

    const filteredSections = useMemo(
      () =>
        searchHistory?.searchBarHistory.map(item => {
          return {
            ...item,
            items: item.items.filter(subItem =>
              subItem.value
                .toLowerCase()
                .includes(searchState.value.toLowerCase()),
            ),
          };
        }),
      [searchState.value, searchHistory?.getItemsSize],
    );

    const filteredItems = useMemo(
      () => filteredSections.flatMap(section => section.items),
      [searchState.value, searchHistory?.getItemsSize],
    );

    let [selectedItem, setSelectedItem] =
      React.useState<SearchBarHistoryItem | null>(null);

    const handleSetSearchParam = ({
      value,
      pathname = '/shop',
    }: {
      pathname?: string;
      value: string;
    }) => {
      searchState.setValue(value);
      searchHistory?.setHistoryItem('recently', { id: nanoid(), value: value });
      router.push(
        createQueryString({
          pathname: pathname,
          name: 'search',
          value: value,
          asMultipleParams: false,
          resetBefore: !!params['search-category'],
          excludeFromReset: ['search-category', 'sort'],
        }),
      );
    };

    let { keyboardProps } = useKeyboard({
      onKeyDown: e => {
        if (e.key === 'ArrowDown') {
          if (selectedItem) {
            const idx = filteredItems.findIndex(
              item => item.id === selectedItem?.id,
            );
            idx !== filteredItems.length - 1 &&
              setSelectedItem(filteredItems[idx + 1]);
          } else {
            setSelectedItem(filteredItems[0]);
          }
        }

        if (e.key === 'ArrowUp') {
          if (selectedItem) {
            const idx = filteredItems.findIndex(
              item => item.id === selectedItem?.id,
            );
            idx !== 0 && setSelectedItem(filteredItems[idx - 1]);
          }
        }

        if (e.key === 'Enter') {
          if (selectedItem && filteredItems?.length > 0) {
            handleSetSearchParam({ value: selectedItem.value });
          } else {
            handleSetSearchParam({ value: searchState.value });
          }
          setIsFocused(false);
          setSelectedItem(null);
          inputRef.current?.blur();
        }

        if (e.key === 'Escape') {
          handleSetSearchParam({ value: '' });
        }
      },
    });

    const handleSelectItemId = useDebounceCallback(
      (item: SearchBarHistoryItem) => {
        setSelectedItem(item);
      },
      30,
    );

    useEffect(() => {
      params['search-category'] &&
        router.push(
          createQueryString({
            pathname: '/shop',
            name: 'search-category',
            value: params['search-category'],
            asMultipleParams: false,
            resetBefore: true,
            excludeFromReset: ['search', 'sort'],
          }),
        );
      params['search']
        ? searchState.setValue(params['search'][0])
        : searchState.setValue('');
    }, []);

    useEffect(() => {
      !RegExp('^\\/([a-zA-Z]{2})\\/shop$').test(pathname) &&
        searchState.setValue('');
    }, [pathname]);

    const handleClickOutside = () => {
      if (!selectedItem) {
        inputRef.current?.blur();
        setIsFocused(false);
      }
    };

    useOnClickOutside(outerRef, handleClickOutside);

    return (
      <search
        id={'header-search-div'}
        ref={outerRef}
        className={styles.container}
      >
        <div className={wrapperClasses}>
          <DropListSearch
            toggleChipDropDown={toggleChipDropDown}
            resetChip={resetChip}
            dropListOpened={chipDropDownOpened}
            selectedChip={chipInitialValue}
            selectChip={selectChip}
          />
          <div className={inputWrapperClasses}>
            <input
              ref={inputRef}
              className={inputClasses}
              {...inputProps}
              {...keyboardProps}
              onFocus={e => {
                inputProps.onFocus && inputProps.onFocus(e);
                setIsFocused(true);
              }}
              onBlur={e => {
                inputProps.onBlur && inputProps.onBlur(e);
              }}
              autoComplete="off"
            />
          </div>
          <button
            className={styles.search_button}
            onClick={() => {
              handleSetSearchParam({ value: searchState.value });
              setIsFocused(false);
            }}
          >
            <Illustration
              name={'search-magnifier'}
              size={20}
              spriteName={'icons'}
            />
          </button>
        </div>

        <Popover
          isActive={isInputFocused && filteredItems?.length > 0}
          popoverRef={popoverRef}
          className={styles.drop_down}
          style={{
            zIndex: 200,
            width: `${outerRef?.current?.getBoundingClientRect().width}px`,
            left: `${outerRef?.current?.getBoundingClientRect().left}px`,
            top: `${outerRef?.current?.getBoundingClientRect().bottom}px`,
          }}
        >
          <div
            onMouseLeave={() => {
              setSelectedItem(null);
            }}
            className={styles['drop_down-content']}
          >
            {filteredSections.map((section, idx) => {
              return section?.items?.length > 0 ? (
                <React.Fragment key={section.title}>
                  <ul className={styles['sections-wrapper']}>
                    <li className={styles['section']}>
                      <Typography className={styles['section-title']}>
                        {section.title}
                      </Typography>
                      <ul className={styles['section-items']}>
                        {section.items.map(item => {
                          const isFocused = selectedItem?.id === item.id;
                          const isSelected = selectedItem?.id === item.id;
                          const isDisabled = false;

                          const optionClasses = cn(styles['section-item'], {
                            [styles['section-item__recently']]:
                              section?.title === 'Recently',
                            [styles['section-item__focused']]:
                              isFocused || isSelected,
                            [styles['section-item__disabled']]: isDisabled,
                          });

                          return (
                            <li
                              className={optionClasses}
                              key={item.id}
                              onClick={e => {
                                e.stopPropagation();
                                handleSetSearchParam({ value: item.value });
                                setIsFocused(false);
                              }}
                              onMouseEnter={
                                !isSelected
                                  ? () => handleSelectItemId(item)
                                  : undefined
                              }
                            >
                              <div className={styles['content']}>
                                <Illustration
                                  name={
                                    section?.icon as IllustrationProps['name']
                                  }
                                  style={
                                    {
                                      color: section?.iconColor,
                                    } as React.CSSProperties
                                  }
                                  spriteName={'icons'}
                                  spanTagClassName={styles['item-icon']}
                                />
                                <Typography as={'span'} id={'main-search-span'}>
                                  {item.value}
                                </Typography>
                              </div>

                              {!!section?.closeButton && (
                                <button
                                  style={{ all: 'unset' }}
                                  onClick={e => {
                                    e.stopPropagation();
                                    searchHistory?.removeHistoryItem(
                                      'recently',
                                      item.id,
                                    );
                                    //handleSetSearchParam({ pathname: pathname, value: '' });
                                  }}
                                >
                                  <Illustration
                                    name={'x-close'}
                                    style={{ color: 'var(--global-grey800)' }}
                                    spriteName={'icons'}
                                    size={22}
                                  />
                                </button>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                  {filteredSections.length - 1 !== idx &&
                    filteredItems.length > 1 && (
                      <Divider direction={'horizontal'} />
                    )}
                </React.Fragment>
              ) : null;
            })}
          </div>
        </Popover>
      </search>
    );
  },
);
