'use client';
import { CardLiveFeedEntity } from '@entities/desktop/cards';
import styles from '@widgets/desktop/live-feed/ui/live-feed.module.scss';
import shuffle from 'lodash.shuffle';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React, { useMemo } from 'react';
import { Autoplay } from 'swiper/modules';
import { useIsClient, useMediaQuery } from 'usehooks-ts';

import { mockedData } from '@/mocks/items';
import { GlobalItem } from '@/shared/types/common';
import { Skeleton } from '@/shared/ui';
import { useStore } from '@/store/context';
import { Swiper, SwiperSlide } from '@/widgets';

export const LiveFeedCarousel = observer(() => {
  const router = useRouter();
  const liveFeed = useStore()?.livefeed;
  const isClient = useIsClient();
  const sortType = liveFeed?.liveFeedSort;

  const is1024Desktop = useMediaQuery('(min-width: 1024px)');
  const is1440Desktop = useMediaQuery('(min-width: 1440px)');
  const is1920Desktop = useMediaQuery('(min-width: 1920px)');

  const extendArray = (
    originalArray: GlobalItem[],
    targetLength: number,
    itemsToAdd: GlobalItem[],
  ) => {
    while (originalArray.length <= targetLength && itemsToAdd.length > 0) {
      originalArray.push([...itemsToAdd].splice(0, 1)[0]);
    }
    return shuffle(originalArray);
  };

  const handleItemsQuantity = ({
    currMedia,
    sortType,
    items,
  }: {
    currMedia: '1920' | '1440' | '1024';
    sortType: 'all' | 'exclusive';
    items: GlobalItem[];
  }) => {
    const mappedQuantity = {
      '1920': 11,
      '1440': 9,
      '1024': 9,
    };

    const filteredItems = items.filter(item =>
      sortType === 'all' ? item : item.type === sortType,
    );
    const quantityByMedia = mappedQuantity[currMedia];
    const concattedItems =
      filteredItems.length < quantityByMedia
        ? extendArray(filteredItems, quantityByMedia, filteredItems)
        : filteredItems;

    return concattedItems;
  };

  //Добавил функционал дополнения массива элементами если длина filteredItems < quantityByMedia тк ломается анимация.

  const itemsToMap = useMemo(
    () =>
      handleItemsQuantity({
        currMedia: is1920Desktop ? '1920' : is1440Desktop ? '1440' : '1024',
        sortType: sortType,
        items: mockedData,
      }),
    [sortType, is1024Desktop, is1440Desktop, is1920Desktop],
  );

  return isClient ? (
    <Swiper
      pagination={{
        clickable: true,
      }}
      loop={isClient ? true : false}
      observer={true}
      autoplay={{
        pauseOnMouseEnter: true,
        delay: 100,
        reverseDirection: true,
        waitForTransition: true,
      }}
      speed={1300}
      breakpoints={{
        1024: {
          slidesPerView: 9,
          spaceBetween: 10.5,
          centeredSlides: false,
        },
        1440: {
          slidesPerView: 9,
          spaceBetween: 14.75,
          centeredSlides: false,
        },
        1920: {
          slidesPerView: 11,
          spaceBetween: 10,
          centeredSlides: false,
        },
      }}
      modules={[Autoplay]}
      style={
        sortType !== 'all'
          ? {
              marginLeft: 0,
              marginRight: 0,
              display: 'flex',
              width: '100%',
            }
          : {}
      }
      className={'swiper-live-feed'}
    >
      {itemsToMap.map((item, idx) => (
        <SwiperSlide
          onClick={() => router.push(`/shop/${item.category}/${item?.id}`)}
          key={`${idx}-${item.info.title}-${item?.info?.price?.old}`}
        >
          {/*<Link
                  className={'anchor'}
                  style={{ zIndex: 1 }}
                  href={`/shop/${item.category}/${item?.id}`}
                />*/}
          <CardLiveFeedEntity
            info={{
              title: item?.info?.title,
              price: {
                old: item?.info?.price?.old,
                current: item?.info?.price?.current,
              },
            }}
            item={item.item}
            rarity={item.rarity}
            tagCategory={item.tagCategory}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <>
      {itemsToMap.map((item, idx) => (
        <Skeleton
          key={`${idx}-skeleton`}
          className={styles['live-feed-skeleton']}
        />
      ))}
    </>
  );
});
