import * as Yup from 'yup';

export const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .min(6, 'Must be at least 6 characters')
    .required('Required'),
  newPassword: Yup.string()
    .min(6, 'Must be at least 6 characters')
    .required('Required')
    .notOneOf(
      [Yup.ref('oldPassword'), ''],
      'The old and new passwords are the same',
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), ''], 'Passwords don’t match')
    .required('Required'),
});
