'use client';
import { useQueryString } from '@hooks/client';
import { FooterLink } from '@widgets/desktop/footer/ui/utils';

import { Illustration } from '@/shared/ui';
import { HyperLink } from '@/shared/ui/desktop/link';
import { AllIcons } from '@/shared/ui/illustration/illustration';

import styles from './footer-links-list.module.scss';

export const FooterLinksList = ({ links }: { links: FooterLink[] }) => {
  const { createQueryString } = useQueryString();

  const handlePress = (href: string, isCategory?: boolean) => {
    if (isCategory) {
      const res = createQueryString({
        pathname: '/shop',
        name: 'category',
        value: href.toLowerCase(),
        asMultipleParams: false,
      });

      return res;
    } else {
      return href;
    }
  };

  return links.map(link => {
    const href = handlePress(link.href, link.isCategory);

    return (
      <HyperLink
        key={`cat-group_0-${link.id}-${link.text}`}
        href={href}
        className={styles['link']}
        icon={
          <Illustration
            style={{ color: link.color }}
            name={link.icon as keyof AllIcons}
            size={18}
            spriteName={'icons'}
          />
        }
      >
        {link.text}
      </HyperLink>
    );
  });
};
