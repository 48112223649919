'use client';

import { observer } from 'mobx-react-lite';
import React from 'react';

import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './category-button.module.scss';
export const HeaderCategoryButton = observer(() => {
  const sidebarStore = useStore().sidebar;

  return (
    <Button
      onClick={() => sidebarStore.toggleSidebar()}
      iconRight={<Illustration name="widget" size={22} spriteName="icons" />}
      text={'Categories'}
      variant="secondary"
      className={styles['category-button']}
    />
  );
});
