import { CartBadgeItemsCounter } from '@features/cart-items-counter';
import { NavigationTabText } from '@widgets/desktop/header/ui/navigation-tab-text';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import { TabItemNavigation } from '@/shared/types/common';
import { Illustration } from '@/shared/ui';

import styles from './navigation-tabs.module.scss';

const tabs: TabItemNavigation[] = [
  {
    id: 0,
    href: '/inventory',
    text: 'Inventory',
    icon: (
      <Illustration
        name={'bag'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#40BB18' }}
      />
    ),
  },
  {
    id: 1,
    href: '/favorite',
    text: 'Wishlist',
    icon: (
      <Illustration
        name={'heart'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#EB3C3C' }}
      />
    ),
  },
  {
    id: 2,
    href: '/cart',
    text: 'Cart',
    icon: (
      <Illustration
        name={'cart'}
        spriteName={'icons'}
        size={30}
        style={{ color: '#6941C6' }}
      />
    ),
  },
];

export const HeaderNavigationTabs = () => {
  return (
    <nav aria-label="navigation-menu" className={styles['tabs']}>
      <ul>
        {tabs.map((tab, idx) => (
          <li key={`navigation-tab-${idx}`}>
            <NavigationTab
              href={tab.href}
              text={tab.text}
              icon={tab.icon}
              withBadge={tab.text === 'Cart'}
            />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export const NavigationTab = ({
  icon,
  text,
  href,
  withBadge,
}: {
  icon: ReactNode;
  text: string;
  href: string;
  withBadge?: boolean;
}) => {
  const CartBadgeCount = dynamic(
    () =>
      import('@/features/cart-items-counter').then(
        mod => mod.CartBadgeItemsCounter,
      ),
    { ssr: false },
  );

  return (
    <Link href={href} className={styles['tab']}>
      {icon}
      {withBadge && <CartBadgeCount />}
      <NavigationTabText href={href}>{text}</NavigationTabText>
    </Link>
  );
};
