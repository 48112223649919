'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import { Autoplay, EffectFade, Swiper, SwiperSlide } from '@widgets/swiper';
import Link from 'next/link';
import React, { ReactNode } from 'react';
import { useIsClient } from 'usehooks-ts';

import styles from './carousel.module.scss';

export const MainPromoTopBannersCarousel = ({
  skeleton,
}: {
  skeleton?: ReactNode;
}) => {
  const isClient = useIsClient();

  if (!isClient) {
    return skeleton;
  }
  return (
    <Swiper
      loop
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      modules={[Autoplay, EffectFade]}
      effect={'fade'}
      className={'swiper-desktop'}
    >
      <SwiperSlide className={styles['swiper-top-banner']}>
        <Link className={'anchor'} href="#scroll-anchor"></Link>
        <BannerImageEntityDesktop loading={'eager'} variant={'concept1'} />
      </SwiperSlide>
      <SwiperSlide className={styles['swiper-top-banner']}>
        <Link className={'anchor'} href="/en/shop?sort=popular"></Link>
        <BannerImageEntityDesktop loading={'eager'} variant={'concept2'} />
      </SwiperSlide>
      <SwiperSlide className={styles['swiper-top-banner']}>
        <Link className={'anchor'} href="#scroll-anchor"></Link>
        <BannerImageEntityDesktop loading={'eager'} variant={'concept3'} />
      </SwiperSlide>
    </Swiper>
  );
};
