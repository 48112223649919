import { ImageLoaderProps } from 'next/image';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

import { IconSvgProps } from '@/shared/types/common';

export type ItemSize =
  | '42'
  | '48'
  | '52'
  | '60'
  | '68'
  | '90'
  | '92'
  | '96'
  | '124'
  | '152'
  | '218';

export type ItemVariant =
  | 'minion'
  | 'buffalo'
  | 'neko'
  | 'rock'
  | 'cow'
  | 'snowball'
  | 'retired-egg'
  | 'japan-egg'
  | 'woodland-egg'
  | 'fly-potion'
  | 'baku'
  | 'carpet'
  | 'feesh'
  | 'caravan'
  | 'box'
  | 'no-item';

export type CategoryItemVariant =
  | 'new'
  | 'under'
  | 'discount'
  | 'pets'
  | 'eggs'
  | 'transport'
  | 'exclusive'
  | 'potions'
  | 'mm2-under'
  | 'mm2-discount'
  | 'mm2-exclusive'
  | 'mm2-guns'
  | 'mm2-knives'
  | 'mm2-new'
  | 'mm2-bundles'
  | 'mm2-pets';

export type BannerCardItemVariant =
  | 'hand'
  | 'hand-yellow'
  | 'lock'
  | 'lock-green'
  | 'clock-yellow'
  | 'clock-blue'
  | 'mail'
  | 'settings'
  | 'gear'
  | 'clock'
  | 'cart'
  | 'ticket-sale'
  | 'heart';

export interface ItemProps<Variant>
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  children?: ReactNode;
  size: ItemSize;
  srcPrefix?: string;
  srcExtension?: 'webp' | 'png' | 'svg';
  variant: Variant;
  imgLoader?: (p: ImageLoaderProps) => string;
  style?: React.CSSProperties;
}

export const isItemVariant = (obj: any): obj is ItemVariant => {
  return obj && typeof obj !== 'undefined';
};
