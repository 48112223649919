import { LiveFeedWidgetProps } from '@widgets/desktop/live-feed/ui/live-feed.types';
import { LiveFeedCarousel } from '@widgets/desktop/live-feed/ui/live-feed-carousel';
import cn from 'clsx';
import dynamic from 'next/dynamic';
import React from 'react';

import { Label } from '@/shared/ui/label';

import styles from './live-feed.module.scss';

export const LiveFeedWidget = ({
  className,
  ...props
}: LiveFeedWidgetProps) => {
  const liveFeedWidgetClasses = cn(styles['live-feed'], className);
  const LiveFeedSortingTabs = dynamic(() =>
    import('./live-feed-sorting-tabs').then(mod => mod.LiveFeedSortingTabs),
  );

  return (
    <div className={liveFeedWidgetClasses} {...props}>
      <div className={styles['top-slot']}>
        <Label
          className={styles['label']}
          labelVariant={'purple'}
          icon={'graph-new-up'}
          variant={'primary'}
        >
          Live Sales
        </Label>
        <LiveFeedSortingTabs />
      </div>

      <div className={styles['bottom-slot']}>
        <LiveFeedCarousel />
      </div>
    </div>
  );
};
