'use client';

import { ListCardsWidget } from '@widgets/desktop';
import React from 'react';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label } from '@/shared/ui';

export const HotSalesItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ListCardsWidget
            items={items}
            cardsVariant={'primary'}
            topSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'fire-square-red'}
                  variant={'primary'}
                >
                  Hot Sales
                </Label>
                <SeeAllButton href={'/shop?sort=popular'} />
              </>
            }
          />
        );
      }}
    </WithShopItems>
  );
};
