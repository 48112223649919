'use client';
import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import { useQueryString } from '@hooks/client';
import { Autoplay, Swiper, SwiperSlide } from '@widgets/swiper';
import Link from 'next/link';
import React from 'react';

import { Skeleton } from '@/shared/ui';

import styles from './carousel.module.scss';

export const MainPromoBottomBannersCarousel = () => {
  const { createQueryString } = useQueryString();

  const href = createQueryString({
    pathname: '/shop',
    name: 'sale',
    value: 'hot-sale',
    asMultipleParams: true,
    enableToggle: true,
  });

  return (
    <Swiper
      loop
      spaceBetween={5}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      modules={[Autoplay]}
      className={'swiper-desktop'}
    >
      <SwiperSlide className={styles['swiper-bottom-banner']}>
        <Link className={'anchor'} href={href}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={<Skeleton className={styles['swiper-bottom-banner']} />}
          variant={'be-cool-1'}
        />
      </SwiperSlide>
      <SwiperSlide className={styles['swiper-bottom-banner']}>
        <Link className={'anchor'} href={href}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={<Skeleton className={styles['swiper-bottom-banner']} />}
          variant={'be-cool-2'}
        />
      </SwiperSlide>
      <SwiperSlide className={styles['swiper-bottom-banner']}>
        <Link className={'anchor'} href={href}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={<Skeleton className={styles['swiper-bottom-banner']} />}
          variant={'save-money'}
        />
      </SwiperSlide>
    </Swiper>
  );
};
