import { BannerImageEntityDesktop } from '@entities/desktop/banner-image';
import Link from 'next/link';
import React, { ReactNode } from 'react';

import styles from '@/app/[locale]/page.module.scss';
import {
  BLOGGERS_COLLABORATION,
  CONSTANT_PROMOTION,
} from '@/shared/constants/links';
import { Plate } from '@/shared/ui/desktop/plate';

export const MainPromoMiddleBanners = ({
  skeleton,
}: {
  skeleton?: ReactNode;
}) => {
  return (
    <>
      <Plate
        variant={'16'}
        className={styles['promo-plate']}
        key={'middle-banner-1'}
      >
        <Link className={'anchor'} href={BLOGGERS_COLLABORATION}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={skeleton}
          variant={'bloggers-collaboration'}
        />
      </Plate>
      <Plate
        variant={'16'}
        className={styles['promo-plate']}
        key={'middle-banner-2'}
      >
        <Link className={'anchor'} href={CONSTANT_PROMOTION}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={skeleton}
          variant={'constant-promotion'}
        />
      </Plate>
      <Plate
        variant={'16'}
        className={styles['promo-plate']}
        key={'middle-banner-3'}
      >
        <Link className={'anchor'} href={''}></Link>
        <BannerImageEntityDesktop
          loading={'lazy'}
          priority={false}
          skeleton={skeleton}
          variant={'social-boost'}
        />
      </Plate>
    </>
  );
};
