'use client';
import { ListCardsWidget } from '@widgets/desktop';

import { SeeAllButton } from '@/shared/components/see-all-button';
import { WithShopItems } from '@/shared/hocs';
import { Label } from '@/shared/ui';

export const PopularItems = () => {
  return (
    <WithShopItems>
      {({ items }) => {
        return (
          <ListCardsWidget
            items={items}
            topSlot={
              <>
                <Label
                  labelVariant={'yellow'}
                  icon={'graph-up'}
                  variant={'primary'}
                >
                  Popular Items
                </Label>{' '}
                <SeeAllButton href={'/shop?sort=popular'} />
              </>
            }
            cardsVariant={'primary'}
          />
        );
      }}
    </WithShopItems>
  );
};
