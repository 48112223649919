'use client';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { AdvertisingCloseButton } from '@/shared/components/advertising-close-button';
import { useStore } from '@/store/context';

export const CloseAdvertisingButton = observer(() => {
  const advStore = useStore()?.advertising;

  return (
    <AdvertisingCloseButton
      onClick={() => advStore?.toggleShowHeaderAdvBlock()}
    />
  );
});
