import { ChangeCurrencyFeature } from '@features/desktop/change-currency';
import { HeaderCategoryButton } from '@widgets/desktop/header/ui/category-button';
import { HeaderNavigationTabs } from '@widgets/desktop/header/ui/navigation-tabs';
import dynamic from 'next/dynamic';
import React from 'react';

import { DropListGame } from '@/features/drop-list-game';
import { DropListProfile } from '@/features/drop-list-profile';
import { DropListBalance } from '@/shared/components/drop-list-balance';
import { InputSearchAutocomplete } from '@/shared/components/input-search-autocomplete';
import { Logo } from '@/shared/ui';

import styles from './header.module.scss';

export const HeaderWidget = () => {
  const DynamicHeaderFilters = dynamic(() =>
    import('@widgets/desktop/header/ui/header-filters').then(
      mod => mod.HeaderFilters,
    ),
  );

  return (
    <div className={styles['header']}>
      <div className={styles.content}>
        <div className={styles['content__top-slot']}>
          <div className={styles['content__top-slot-left']}>
            <Logo className={styles['logo']} />
          </div>

          <div className={styles['content__top-slot-middle']}>
            <HeaderCategoryButton />
            <InputSearchAutocomplete />
          </div>

          <div className={styles['content__top-slot-right']}>
            <HeaderNavigationTabs />
            <DropListProfile />
          </div>
        </div>

        <div className={styles['content__bottom-slot']}>
          <div className={styles['content__bottom-slot-left']}>
            <DynamicHeaderFilters />
          </div>

          <div className={styles['content__bottom-slot-right']}>
            <DropListGame />
            <DropListBalance />
            <ChangeCurrencyFeature />
          </div>
        </div>
      </div>
    </div>
  );
};
