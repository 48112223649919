'use client';
import styles from '@widgets/desktop/header/ui/navigation-tabs.module.scss';
import cn from 'clsx';
import { usePathname } from 'next/navigation';
import React, { ReactNode } from 'react';

import { Typography } from '@/shared/ui';

export const NavigationTabText = ({
  children,
  href,
}: {
  children: ReactNode;
  href: string;
}) => {
  const pathname = usePathname();

  return (
    <Typography
      className={cn(styles['tab-text'], {
        [styles['active']]: String(pathname).includes(href),
      })}
    >
      {children}
    </Typography>
  );
};
