import { CloseAdvertisingButton } from '@features/desktop/adverising/close';
import { LearnMoreButton } from '@features/desktop/adverising/learn-more';

import { AdvertisingEntity } from '@/entities';
import { Typography } from '@/shared/ui/typography';

import styles from './adsveritising.module.scss';

type AdvertisingWidgetProps = {};

export const AdvertisingWidget = ({}: AdvertisingWidgetProps) => {
  return (
    <AdvertisingEntity
      className={styles['advertising']}
      middleSlot={
        <div className={styles['content']}>
          <Typography className={styles['text']}>
            Get a 5% deposit bonus
          </Typography>
          <LearnMoreButton />
        </div>
      }
      rightSlot={<CloseAdvertisingButton />}
    />
  );
};
