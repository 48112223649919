import cn from 'clsx';
import Link from 'next/link';

import { HyperLinkProps } from '@/shared/ui/desktop/link/link.types';
import { Typography } from '@/shared/ui/typography';

import styles from './link.module.scss';
export const HyperLink = ({
  children,
  icon,
  className,
  href,
}: HyperLinkProps) => {
  return (
    <Typography href={href} as={Link} className={cn(styles.link, className)}>
      {icon}
      {children}
    </Typography>
  );
};
