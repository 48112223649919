'use client';
import { CardCategoryEntity } from '@entities/desktop/cards';
import { observer } from 'mobx-react-lite';
import React from 'react';

import {
  mockedCategories,
  mockedMM2Categories,
} from '@/mocks/market-categories';
import { DivElementType } from '@/shared/types/common';
import { useStore } from '@/store/context';

import styles from './categories.module.scss';

type CategoriesWidgetProps = DivElementType & {};

export const CategoriesWidget = observer(({}: CategoriesWidgetProps) => {
  const selectedGame = useStore()?.app.selectedGame;

  const categories =
    selectedGame === 'adopt' ? mockedCategories : mockedMM2Categories;

  return (
    <>
      {Array.isArray(categories) && categories?.length > 0 && (
        <div className={styles['container']}>
          {categories?.map((category, idx) => {
            return (
              <CardCategoryEntity
                key={`${idx}-${category?.id}`}
                categoryPath={category?.categoryPath}
                item={category?.item}
                rarity={category?.rarity}
                title={category?.title}
                subtitle={category?.subtitle}
              />
            );
          })}
        </div>
      )}
    </>
  );
});
